import * as React from "react";
import "@fontsource/dm-sans";
import "./apropos.sass";
import vision from "../images/vision_black.png";
import mission from "../images/mission_black.png";
import Layout from "../components/Layout";

// markup
const Apropos = () => {
  return (
    <Layout
      title="À propos"
      description="Plateforme suisse de conseils et services juridiques pour entreprises et particuliers. Accès simples et rapides à un avocat par téléphone ou en personne."
    >
      <div id="apropos-holder">
        <h5>À propos</h5>
        <h2 id="aph">Simplifier l'accès aux services légaux</h2>
        <p id="center">
          Legalify.ch est une plateforme suisse de services juridiques pour
          entreprises et particuliers. Elle offre une gamme de prestations pour
          toute société ou personne à la recherche de conseils ou d'assistance
          en matière juridique. La plateforme est conçue pour faciliter l'accès
          à des services juridiques spécifiques, pour que ses utilisateurs
          obtiennent des réponses et des solutions efficaces à leurs problèmes.
        </p>
        <div id="vision-mission">
          <div id="vision">
            <div id="vision-text">
              <h5>Qui sommes-nous ?</h5>
              <h2>Une plateforme créée par des professionnels</h2>
              <p>
                La plateforme Legalify.ch a été créée et développée par Me
                Pierre Vuille et Me Christophe Zermatten, tous deux avocats
                associés au sein de l'étude{" "}
                <a
                  id="link"
                  href="https://gvalaw.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  GVA law
                </a>{" "}
                à Genève, partenaire de la plateforme. Me Vuille et Me Zermatten
                sont tous deux membres de la Fédération suisse des avocats et de
                l'Ordre des avocats de Genève. Ils bénéficient de plusieurs
                années d'expériences en tant qu'avocats dans les domaines du
                droit proposés sur la plateforme, notamment ceux concernant les
                entreprises et les services juridiques offerts aux particuliers.
              </p>
            </div>
            <img src={vision} alt="our vision"></img>
          </div>
          <div id="mission">
            <img src={mission} alt="our mission"></img>
            <div id="mission-text">
              <h5>Notre vision</h5>
              <h2>Une approche digitale</h2>
              <p>
                Legalify.ch a été pensée dans l’idée d’offrir aux entreprises et
                aux particuliers un accès pratique à des informations
                juridiques, ainsi qu'un contact facilité avec nos avocats et nos
                juristes partenaires au sein de l'Étude GVA law. La plateforme
                permet à ses utilisateurs de choisir les prestations les plus
                adaptées à leurs besoins parmi une gamme étendue de services
                juridiques. Plusieurs domaines sont accessibles et détaillés sur
                la plateforme, qui permet de contacter nos experts de manière
                simple et transparente.
              </p>
            </div>
          </div>
          <div id="vision">
            <div id="vision-text">
              <h5>Notre mission</h5>
              <h2>Accompagner nos clients</h2>
              <p>
                Que vous soyez à la recherche d’un conseil juridique pour
                débuter votre aventure entrepreneuriale en toute sérénité ou
                protéger vos intérêts dans des moments clés de votre vie
                personnelle, nos avocats vous assistent. Legalify.ch vous permet
                de créer facilement votre société en limitant les coûts de
                constitution, d’obtenir en quelques minutes des documents
                juridiques prêts à être utilisés, de réserver un entretien de
                conseil avec l’un de nos avocats ou encore de souscrire un
                abonnement mensuel pour recevoir une assistance et des conseils
                juridiques selon vos besoins.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Apropos;
